export enum CustomEvent {
  CALENDAR_RESPONSE_SUCCESS = 'calendar-response-success',
  CALENDAR_OLD_RESPONSE_SUCCESS = 'calendar-old-response-success',
  SHOW_ERROR_MODAL = 'show-error-modal',
  PURCHASE_LIST_CHANGE = 'purchase-list-change',
  PLACE_ORDER_CHANGE = 'place-order-change',
  WEB_SHOP_STATE_RESTORED = 'web-shop-state:restored',
  WEB_SHOP_STATE_PERSISTED = 'web-shop-state:persisted',
  OPEN_CM_CHAT = 'open-cm-chat',
  LOCATION_SWITCHED = 'location-switched',
  USER_LOGOUT = 'user-logout',
  OPEN_CHAT_SLIDE_OVER = 'open-chat-slide-over'
}
